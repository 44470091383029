
import { Vue, Options } from 'vue-class-component';
import { Patient, Status } from '@/models';
import { Action } from '@/models/episode-of-care/episode-of-care.model';
import { IColumn } from '@/lib';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import { FEATURES } from '@/constants';
import {
  BaseButton,
  BaseIcon,
  BasePopover,
  PopoverButton,
  SpecialityButton,
  BasePagination,
  ReviewSummary,
  PopoverLink,
  DataTable
} from '@/lib/components';
import { useSessionStore } from '@/stores/session.store';

@Options({
  props: {
    patient: {
      type: Object,
      required: true
    },
    rows: {
      type: Array,
      default: () => []
    },
    perPage: { type: Number, default: 0 },
    page: { type: Number, default: 1 },
    total: { type: Number, default: 0 },
    sort: { type: String, default: 'created.time' }
  },
  components: {
    DataTable,
    BaseButton,
    BaseIcon,
    BasePopover,
    PopoverButton,
    SpecialityButton,
    BasePagination,
    ReviewSummary,
    PopoverLink
  }
})
export default class PatientConsultsDataTable extends Vue {
  patient!: Patient;
  sessionStore = useSessionStore();
  total!: number;
  perPage!: number;
  page!: number;

  // Consults
  isLocationFeatureEnabled = false;
  loadingConsults = false;
  loadingAction = false;

  columns: IColumn[] = [];

  beforeCreate() {
    this.isLocationFeatureEnabled = isFeatureFlagEnabled(
      FEATURES.ORGANISATION_LOCATIONS
    );
    this.columns = [
      {
        name: 'created.time',
        label: this.$t('platform.common.created'),
        sortable: true
      },
      {
        name: 'clinic_configuration_name',
        label: this.$t('custom.uhb.consult.clinic'),
        sortable: true
      },
      {
        name: 'consult_type',
        label: this.$t('platform.common.type'),
        sortable: true
      },
      { name: 'status', label: this.$t('platform.common.status') },
      {
        name: 'organisation_unit_owner_title',
        label: this.$t('platform.common.referring_organisation')
      }
    ];
  }

  get currentUserId() {
    return this.sessionStore.currentUser.id;
  }

  get tableColumns() {
    return [
      ...this.columns,
      ...(this.isLocationFeatureEnabled
        ? [{ name: 'location', label: this.$t('custom.uhb.consult.site') }]
        : []),
      ...[{ name: 'action', label: '', align: 'right' }]
    ];
  }

  hasPermission(permission: string) {
    return this.sessionStore.permissions.includes(permission);
  }

  async updateSort(sort: string) {
    await this.$router.replace({
      path: this.$route.path,
      query: {
        sort
      }
    });
  }

  formatDate(date: string) {
    return this.$d(new Date(date), 'shortMonth');
  }

  statusColor(value: Status): string {
    switch (value) {
    case 'Needs Scheduling':
    case 'Awaiting Consult':
      return 'text-gray-500';
    case 'Awaiting Review':
    case 'Consulting':
    case 'Reviewing':
      return 'text-primary-500';
    case 'Sent to Emergency':
    case 'Rejected':
      return 'text-danger-500';
    case 'Complete':
    case 'Resolved Rejected':
      return 'text-success-500';
    default:
      return 'text-gray-500';
    }
  }

  async changePage(page: number) {
    // Keep all existing query parameters
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        page: page.toString()
      }
    });
  }

  showCTAButton(action: Action): boolean {
    const label: string = this.showViewLabel(action.status);
    const isBlocked = typeof action.lockedBy?.id !== 'undefined';
    if (isBlocked) {
      const isCurrentUserBlocking = Boolean(
        action.lockedBy.id === this.currentUserId
      );
      return isCurrentUserBlocking && label !== '';
    }
    return Boolean(label !== '');
  }

  showViewLabel(status: Status): string {
    if (
      this.hasPermission('review:read') &&
      (status === 'Complete' || status === 'Sent to Emergency')
    ) {
      return this.$t('platform.common.view') as string;
    }

    switch (status) {
    case 'Needs Scheduling':
      return this.$t('custom.uhb.worklist.schedule') as string;
    case 'Awaiting Consult':
      return this.$t('platform.common.start') as string;
    case 'Consulting':
      return this.$t('platform.common.resume') as string;
    case 'Awaiting Review':
      return this.$t('custom.uhb.review.to-review') as string;
    case 'Reviewing':
      return this.$t('platform.common.continue') as string;
    case 'Complete':
    case 'Sent to Emergency':
      return this.$t('custom.uhb.review.summary.summary') as string;
    default:
      return '';
    }
  }

  viewButtonIsDisabled(action: Action): boolean {
    if (this.loadingAction) {
      return true;
    }
    if (action.status === 'Needs Scheduling') {
      return !this.hasPermission('consult:schedule');
    } else if (
      action.status === 'Awaiting Consult' ||
      action.status === 'Consulting'
    ) {
      return !this.hasPermission('consult:update');
    } else if (
      action.status === 'Reviewing' ||
      action.status === 'Awaiting Review'
    ) {
      return !this.hasPermission('review:update');
    } else if (
      action.status === 'Complete' ||
      action.status === 'Sent to Emergency'
    ) {
      return !this.hasPermission('review_summary:read');
    }
    return false;
  }

  startView(action: Action) {
    switch (action.status) {
    case 'Needs Scheduling':
      this.$emit('showScheduleConsultModal', action.id);
      break;
    case 'Awaiting Consult':
      this.$emit('startConsult', action);
      break;
    case 'Consulting':
      this.$emit('resumeConsult', action);
      break;
    case 'Awaiting Review':
    case 'Reviewing':
      this.$emit('startReview', action);
      break;
    case 'Complete':
    case 'Sent to Emergency':
      this.$emit('viewReviewSummary', action);
      break;
    case 'Rejected':
      this.$emit('openResolveReasonModal', action);
      break;
    }
  }
}
